import moment from 'moment';

const get = (dateTime, selectedLanguage, year) =>
{
    let lang = localStorage.getItem('language');

    if (selectedLanguage == 'ar' || lang == 'ar')
    {
        const dateTimeEvent = new Date(moment(dateTime).utc());
        let options = { month: 'long', day: 'numeric', };

        if (year)
        {
            options = { year: 'numeric', month: 'long', day: 'numeric', };
        }


        return dateTimeEvent.toLocaleDateString('ar-EG', options);
    }
    else
    {
        return dateTime
    }
}

export default {
    get
}