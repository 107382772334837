<template>
    <div v-if="modal">
        <v-dialog v-model="modal"
                  width="80%">
            <template>

            </template>
            <v-card>
                <div v-if="showAlert">
                    <alert :message="alertMessage"
                           :type="alertType"></alert>

                </div>
                <v-card-title>

                    <span class="text-lg-2xl text-xl font-weight-semibold text-black"
                          style="margin-bottom: 0;">
                        {{ $t(`Choose transaction your are disputing`) }}
                    </span>
                    <span @click="dialogOpen"
                          class="arabic-close"
                          style="position: absolute; right: 10px;cursor: pointer;">
                        <v-icon>
                            {{ icons.mdiClose }}
                        </v-icon>
                    </span>
                    <!-- <br> -->
                    <!-- <span class="text-h5 text-color-black mt-8"
                          style="font-size: 18px !important;margin-bottom: -9px;">Please read the below
                        Terms and Conditions and accept by checking the box in order to continue with your
                        enrollment.</span> -->
                </v-card-title>
                <v-card-text style="overflow: auto;height: 70vh;">

                    <div class="security-dark"
                         style="overflow: auto;">
                        <table style="width:100%;min-width: 800px;">
                            <tr>
                                <td> <span :class="{ 'pr-1': selectedLanguage == 'ar' }"
                                          style="margin-bottom: 0;font-size: 12px !important;">
                                        {{ $t(`Cardmember name`) }}
                                    </span>
                                    <br>
                                    <span class="text-lg-2xl text-xl font-weight-semibold text-black pt-5 mb-2"
                                          style="margin-bottom: 0;font-size: 13px !important;">
                                        {{ transaction.subtitle }}
                                    </span>
                                </td>

                                <td> <span style="margin-bottom: 0;font-size: 12px !important;">
                                        {{ $t(`Card Number`) }}
                                    </span>
                                    <br>
                                    <span class="text-lg-2xl text-xl font-weight-semibold text-black pt-5 mb-2"
                                          style="margin-bottom: 0;font-size: 13px !important;">
                                        {{ selectedCard.primeCardSerno }}
                                    </span>
                                </td>

                                <td colspan="2"> <span style="margin-bottom: 0;font-size: 12px !important;">
                                        {{ $t(`Merchant`) }}
                                    </span>
                                    <br>
                                    <span class="text-lg-2xl text-xl font-weight-semibold text-black pt-5 mb-2"
                                          style="margin-bottom: 0;font-size: 13px !important;">
                                        {{ transaction.merchant }}
                                    </span>
                                </td>
                                <td><span style="margin-bottom: 0;font-size: 12px !important;">
                                        {{ $t(`Location`) }}
                                    </span>
                                    <br>
                                    <span class="text-lg-2xl text-xl font-weight-semibold text-black pt-5 mb-2"
                                          style="margin-bottom: 0;font-size: 13px !important;">
                                        {{ transaction.location }}
                                    </span>
                                </td>
                            </tr>
                            <tr class="mt-5">
                                <td> <span :class="{ 'pr-1': selectedLanguage == 'ar' }"
                                          style="margin-bottom: 0;font-size: 12px !important;">
                                        {{ $t(`Transaction Amount`) }}
                                    </span>
                                    <br>
                                    <span class="text-lg-2xl text-xl font-weight-semibold text-black pt-5 mb-2"
                                          style="margin-bottom: 0;font-size: 13px !important;">
                                        {{ transaction.transAmountCur }} {{ transaction.transAmt }}
                                    </span>
                                </td>
                                <td><span style="margin-bottom: 0;font-size: 12px !important;">
                                        {{ $t(`Billed Amount`) }}
                                    </span>
                                    <br>
                                    <span class="text-lg-2xl text-xl font-weight-semibold text-black pt-5 mb-2"
                                          style="margin-bottom: 0;font-size: 13px !important;">
                                        {{ transaction.transAmountCur }} {{ transaction.billedAmount }}
                                    </span>
                                </td>
                                <td> <span style="margin-bottom: 0;font-size: 12px !important;">
                                        {{ $t(`Transaction date`) }}
                                    </span>
                                    <br>
                                    <span class="text-lg-2xl text-xl font-weight-semibold text-black pt-5 mb-2"
                                          style="margin-bottom: 0;font-size: 13px !important;">
                                        {{ transaction.date }}
                                    </span>
                                </td>
                                <td><span style="margin-bottom: 0;font-size: 12px !important;">
                                        {{ $t(`Posting date`) }}
                                    </span>
                                    <br>
                                    <span class="text-lg-2xl text-xl font-weight-semibold text-black pt-5 mb-2"
                                          style="margin-bottom: 0;font-size: 13px !important;">
                                        {{ transaction.postingDate }}
                                    </span>
                                </td>
                                <td><span style="margin-bottom: 0;font-size: 12px !important;">
                                        {{ $t(`Serial No.`) }}
                                    </span>
                                    <br>
                                    <span class="text-lg-2xl text-xl font-weight-semibold text-black pt-5 mb-2"
                                          style="margin-bottom: 0;font-size: 13px !important;">
                                        {{ transaction.serialNo }}
                                    </span>
                                </td>
                            </tr>
                        </table>


                    </div>
                    <div class="security-dark mt-4 py-4">
                        <p :class="{ 'pr-2': selectedLanguage == 'ar' }"
                           class="mb-2 text-color-black">
                            {{ $t('Upload a proof of claim') }}
                        </p>
                        <input id="fileUpload"
                               type="file"
                               hidden
                               @change="uploadImg">
                        <div style="height: 49px;padding: 9px 11px; display: flex;"
                             class="file-flex">
                            <div class="d-flex btn"
                                 style="border: 1px solid #006FCF;height: 38px;padding: 2px 17px;border-radius: 8px;">


                                <img src="@/assets/images/Upload-Filled.png"
                                     style="width: 16px;height: 18px;position: relative;top: 7px;left: 11px;right: 15px;"
                                     alt="">
                                <v-button @click="chooseFiles()"
                                          class="text-blue font-weight-bold mx-4">
                                    <span style="padding: 11px 18px;position: relative;top: 7px;">{{ $t('Upload') }}</span>
                                </v-button>
                            </div>

                            <span v-if="closeIcon"
                                  style="padding: 9px 23px;font-size: 13px;"
                                  class="text-color-black file-text">{{ fileName }}
                                <v-icon style="cursor: pointer;"
                                        @click="close">
                                    {{ icons.mdiClose }}
                                </v-icon>
                            </span>
                            <span v-if="!closeIcon"
                                  style="padding: 9px 23px;font-size: 13px;"
                                  class="text-color-black file-text">{{ $t('Max file size 5MB') }}
                            </span>
                        </div>


                    </div>
                    <div class="pr-4 security-dark mt-4 pt-1">
                        <div class="d-flex justify-space-between mt-6">
                            <p class="mb-2 text-color-black">
                                {{ $t('Additional information*') }}
                            </p>
                            <p dir="ltr"
                               class="mb-2 text-black font-weight-regular">
                                {{ counter }} {{ $t('/500') }}
                            </p>
                        </div>
                        <v-row>
                            <v-col lg="12"
                                   md="12"
                                   sm="12">
                                <v-textarea solo
                                            v-model="information"
                                            @keyup="submit($event)"
                                            :rules="genericRules"
                                            :label="$t('Add Message')">
                                    <template #message="{ message }">
                                        {{ $t(message) }}
                                    </template>
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row class="mt-5"
                           justify="center">

                        <v-col lg="3"
                               md="6"
                               sm="12">
                            <v-btn class="text-blue"
                                   style="border: 1px solid #016FD0;"
                                   @click="dialogOpen"
                                   x-large
                                   block>
                                {{ $t('Cancel') }}
                            </v-btn>
                        </v-col>
                        <v-col lg="3"
                               md="6"
                               sm="12">
                            <v-btn color="primary"
                                   x-large
                                   @click="disputeBill"
                                   :disabled="!fileUploded || information == ''"
                                   :rules="genericRules"
                                   block>
                                {{ $t('Send') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import
{
    mdiClose
} from '@mdi/js'
import soapServices from '@/services/soap-services';
import store from '@/store';
import moment from 'moment'
import { DeviceUUID } from 'device-uuid'
import sharedServices from '@/services/shared-services';
import alert from '../alert.vue';
import getArabicNumbers from '@/@core/utils/getArabicNumbers';
export default {
    components: {
        alert
    },
    props: {

        modal: {
            default: false,
            type: Boolean
        },
        transaction: {
            default: {},
            type: {}
        }
    },
    data: () => ({
        icons: { mdiClose },
        newFileName: 'Max file size 5MB',
        selectedCard: '',
        fileUploded: false,
        fileName: 'Max file size 5MB',
        closeIcon: false,
        genericRules: [
            v => !!v || 'Field is required'
        ],
        alertMessage: '',
        alertType: 'error',
        showAlert: false,
        selectedLanguage: 'en',
        counter: 0,
    }),
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        }
        this.selectedCard = store.get('card/details');
    },

    methods: {
        dialogOpen()
        {
            this.$emit('close');
            return this.modal = !this.modal;
        },
        dialogClose()
        {
            this.$emit('success');
            return this.modal = !this.modal;
        },
        chooseFiles: function ()
        {
            document.getElementById("fileUpload").click()
        },
        close()
        {
            this.fileName = 'Max file size 5MB';
            this.fileUploded = false;
            this.closeIcon = false;
            document.getElementById("fileUpload").value = null
        },
        uploadImg(event)
        {
            let file = event.target.files[0];
            return new Promise((resolve, reject) =>
            {

                let fileSize = event.target.files[0].size / 1024 / 1024;
                const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

                if (fileSize <= 5)
                {
                    if (allowedExtensions.includes(fileExtension))
                    {
                        const reader = new FileReader();
                        reader.readAsDataURL(event.target.files[0]);
                        reader.onload = () =>
                        {
                            let fileName = file.name.replace(/[^a-zA-Z ]/g, "");

                            let base64Image = reader.result;
                            base64Image = base64Image.replace(/^data\:([^\;]+)\;base64,/gmi, '');
                            this.uploadcardLimitImage(base64Image, file.size, fileName)
                        };
                    }
                    else
                    {
                        let alertMessage = 'Max file size: 5MB | Format: png, jpg and pdf'
                        this.alertMessage = alertMessage
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                }
                else
                {
                    let alertMessage = 'File is too large, maximum file size is 5MB'
                    this.alertMessage = alertMessage
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }

            });
        },
        uploadcardLimitImage(img, size, name) // xml request payload to soap-services
        {
            let obj = {
                idSeed: sharedServices.getIdSeed(),
                sysId: new DeviceUUID().get(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysAuth: store.get('requestKeys/accessToken'),
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                requestorID: localStorage.getItem('userName'),
                base64Binary: img,
                filename: 'assets.jpg',
                length: size
            }

            soapServices.changeCardLimit(obj).then(soapResp =>
            {
                if (soapResp?.SaveDocumentResponse?.SaveDocumentResult?.IntDocService?.Result?.ErrorCode == '000')
                {
                    this.newFileName = soapResp?.SaveDocumentResponse?.SaveDocumentResult?.IntDocService?.Body?.FileName
                    this.fileUploded = true;
                    let alertMessage = 'File uploaded Successfully'
                    this.alertMessage = alertMessage
                    this.alertType = 'success'
                    this.showAlert = true;
                    this.fileName = name
                    this.closeIcon = true;
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },
        disputeBill() 
        {
            let obj = {
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysId: new DeviceUUID().get(),
                sysAuth: store.get('requestKeys/accessToken'),
                requestorID: localStorage.getItem('userName'),
                idSeed: sharedServices.getIdSeed(),
                custNo: store.get('requestKeys/ClientCode'),
                accNo: this.selectedCard.accountNo,
                cardNo: this.selectedCard.primeCardSerno,
                accStmtDate: moment(this.transaction.dateTime).format('DD/MM/yyyy'),
                posDate: moment(this.transaction.postingDate).format('DD/MM/yyyy'),
                trxnDate: moment(this.transaction.date).format('DD/MM/yyyy'),
                description: this.transaction.description,
                subtitle: this.transaction.subtitle,
                last4digits: this.selectedCard.Last4Digits,
                transCurCode: this.transaction.transCurCode,
                cachBackAmt: this.transaction.cachBackAmt,
                BillingAmount: this.transaction.amount,
                transAmt: this.transaction.transAmt,
                transId: this.transaction.transId,
                clientMessage: this.information,
                fileName: this.newFileName
            }

            soapServices.bilingDispute(obj).then(soapResp =>
            {
                if (soapResp?.SRBillingDisputeResponse?.SRBillingDisputeResult?.ServiceRequest?.Result?.ErrorCode == '000')
                {
                    let alertMessage = 'Dispute bill Successfully'
                    this.alertMessage = alertMessage
                    this.alertType = 'success'
                    this.showAlert = true
                    this.dialogClose();
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },
        submit(e)
        {
            if (e.key == 'Backspace')
            {
                if (this.information)
                {
                    if (this.selectedLanguage == 'en')
                    {
                        if (this.counter != 0)
                        {
                            this.counter = this.counter - 1;
                        }
                    }
                    else
                    {
                        if (this.engCount != 0)
                        {
                            this.counter = this.engCount ? this.engCount - 1 : this.counter - 1;
                            this.engCount = this.counter;
                            let count = String(this.counter);
                            this.counter = getArabicNumbers.getArNumber(count);
                            if (this.counter == '۰')
                            {
                                this.counter = 0;
                                this.engCount = 0;
                            }
                        }
                    }
                } else
                {
                    this.counter = 0;
                    this.engCount = 0;
                }

            }
            else
            {
                if (this.selectedLanguage == 'en')
                {
                    this.counter = this.counter + 1
                }
                else
                {
                    this.counter = this.engCount ? this.engCount + 1 : this.counter + 1;
                    this.engCount = this.counter;
                    this.counter = String(this.counter);
                    this.counter = getArabicNumbers.getArNumber(this.counter);
                }

            }
        },
    }
}
</script>
  
<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

table>tr>td {
    padding: 13px 0px;
}

::v-deep .v-application.theme--light .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    /* box-shadow: 0 1px 3px 0 rgb(94 86 105 / 20%), 0 3px 1px -2px rgb(94 86 105 / 12%), 0 2px 2px 0 rgb(94 86 105 / 14%); */
    box-shadow: none !important;
}

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

@media screen and (max-width: 481px) {
    .btn {
        width: 190px;
        margin-bottom: 5px;
    }

    .file-flex {
        display: block !important;
    }

    .file-text {
        padding: 0px !important;
    }
}</style>
  