<template>
    <div style="padding: 0;">
        <div class=" main-container-width">
            <v-card v-for="(item, index) in transactionsArray"
                    :key="index"
                    class="mt-3"
                    style="width: 98%;overflow-x: auto;overflow-y: hidden;">

                <div class="pa-3"
                     style="min-width: 700px;">
                    <v-row style="min-height: 75px;cursor: pointer;"
                           @click="openPopup(item)">
                        <v-col cols="4"
                               :lg="showBtn ? 3 : 4"
                               md="4"
                               sm="4"
                               class="d-flex">
                            <div class="d-flex flex-column justify-center">
                                <div>
                                    <v-img contain
                                           style="height:30px !important;width: 30px;"
                                           class="auth-3d-group overflow-hidden"
                                           :src="item.icon">
                                    </v-img>
                                    <!-- </v-img> -->
                                </div>
                            </div>
                            <div class="d-flex flex-column justify-center mx-3">
                                <span class="font-weight-regular"
                                      style="color:#8A8A8A;font-size:13px;">
                                    {{ $t('Posting Date') }}:
                                    {{ getArabicDate(item.dateTime) }}
                                </span>
                                <div class="text-black font-weight-semibold"
                                     style="font-size:12px;"
                                     :class="{ successColor: item.cr }"> {{ item.cr ? item.title : item.title }}
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="4"
                               lg="4"
                               md="4"
                               sm="4"
                               class="d-flex justify-center">
                            <div style="top:-11px;position: relative">
                                <v-divider class="my-3 mr-5 mx-3"
                                           :vertical="true"></v-divider>
                                <v-img contain
                                       style="left: 5px;position: absolute;top: 50%;background: white;box-shadow: 0 2px 10px 0 rgba(94, 86, 105, 0.1);width: 15px;"
                                       class="arrowTrans auth-3d-group overflow-hidden"
                                       :src="arrowImage">
                                </v-img>
                            </div>

                            <div :class="{ 'mr-2': selectedLanguage == 'ar' }"
                                 class="d-flex flex-column justify-center">
                                <div class="font-weight-regular"
                                     style="color:#8A8A8A;font-size:13px;">
                                    {{ $t('Transaction Date') }}:
                                    {{ getArabicDate(item.postingDate) }}
                                </div>
                                <div class="text-black font-weight-semibold"
                                     style="font-size:12px;"> {{ item.subtitle }}
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="2"
                               :lg="showBtn ? 2 : 3"
                               md="2"
                               sm="2"
                               class="d-flex">
                            <v-divider class="my-1 mx-4"
                                       :vertical="true"></v-divider>
                            <div class="d-flex flex-column justify-center">
                                <div class="font-weight-regular text-blue"
                                     style="font-size: 11px;"> {{ $t('Amount') }}
                                </div>
                                <div class="text-black font-weight-semibold"
                                     style="font-size: 12px;">
                                    <span v-if="item.amount != item.billedAmount">
                                        <span v-if="selectedLanguage == 'en'">
                                            {{ $t(item.transAmountCur) }}
                                        </span>
                                        {{ item.billedAmount | VMask(currencyMask) }} |
                                        <span v-if="selectedLanguage == 'ar'">
                                            {{ $t(item.transAmountCur) }}
                                        </span>
                                    </span>
                                    <span v-if="selectedLanguage == 'en'">
                                        {{ $t(item.currencyCode) }}
                                    </span>
                                    {{ item.amount | VMask(currencyMask) }}
                                    <span v-if="selectedLanguage == 'ar'">
                                        {{ $t(item.currencyCode) }}
                                    </span>
                                </div>
                            </div>
                        </v-col>
                        <v-col v-if="showBtn"
                               lg="2"
                               md="2"
                               sm="2"
                               cols="2"
                               class="d-flex">
                            <v-btn color="primary"
                                   type="button"
                                   style="width: 100%;position: relative;top: 12px; font-size: 14px;"
                                   @click="onDetails($event, item)"
                                   x-large
                                   class=" py-3 mb-5">
                                {{ $t('Select Transaction') }}
                            </v-btn>
                        </v-col>
                        <v-col v-if="!showBtn"
                               lg="1"
                               md="2"
                               sm="2"
                               cols="2"
                               class="d-flex">
                            <v-img contain
                                   style="height: 16px;    margin-top: 20px;"
                                   class="auth-3d-group overflow-hidden image"
                                   :src="require(`@/assets/images/icons/iota-circle.png`)">
                            </v-img>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>
        <div class="text-center">
            <v-dialog v-model="dialog"
                      width="700">
                <v-card class="">
                    <v-card-title>
                        <span class="text-h5 text-color-black">{{ $t('Transaction Details') }}</span>
                        <span @click="closeDialog"
                              class="arabic-close en-close">
                            <v-icon>
                                {{ icons.mdiClose }}
                            </v-icon>
                        </span>
                    </v-card-title>
                    <v-card-text class="card-text">

                        <v-row class="d-flex">
                            <v-col cols="12"
                                   lg="12"
                                   style="overflow-y: auto;">

                                <table class="table-transactions"
                                       style="min-width: 660px;">
                                    <tr style="height: 40px;"
                                        v-for="item in result">

                                        <td style="text-transform: capitalize;">
                                            {{ $t(item.key) }}
                                        </td>
                                        <td class="font-weight-bold"
                                            v-if="item.key != 'Transaction Date' && item.key != 'Posting Date'">

                                            <span v-if="item.key != 'Transaction Amount' && item.key != 'Billing Amount'">
                                                {{ item.value }}
                                            </span>

                                            <span v-if="item.key == 'Transaction Amount' || item.key == 'Billing Amount'">
                                                <span v-if="selectedLanguage == 'en'">
                                                    {{ $t(item.value.cur) }}
                                                </span>
                                                {{ item.value.amt | VMask(currencyMask) }}
                                                <span v-if="selectedLanguage == 'ar'">
                                                    {{ $t(item.value.cur) }}
                                                </span>
                                            </span>
                                        </td>
                                        <td class="font-weight-bold"
                                            v-if="item.key == 'Transaction Date' || item.key == 'Posting Date'">
                                            {{ getArabicDate(item.value) }}
                                        </td>
                                    </tr>
                                </table>
                            </v-col> </v-row>

                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline


import BillingForm from '../billing-dispute/BillingForm.vue';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import store from '@/store';
import arabicDate from '@/@core/utils/arabicDate';


const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
import
{
    mdiClose
} from '@mdi/js'
export default {
    components: {
        BillingForm
    },
    props: {
        transactionsArray: {
            default: [],
            type: []
        },
        showBtn: {
            default: false,
            type: Boolean
        },

    },
    data: () => ({
        currencyMask,
        modall: false,
        transaction: {},
        arrowLeft: require(`@/assets/images/icons/arrow-left.png`),
        arrowRight: require(`@/assets/images/icons/arrow-right.png`),
        arrowImage: null,
        selectedLanguage: null,
        dialog: false,
        item: null,
        result: [],
        icons: { mdiClose },
    }),
    mounted()
    {
        this.selectedLanguage = localStorage.getItem('language');
        if (this.selectedLanguage == 'ar')
        {
            this.arrowImage = this.arrowLeft;
        }
        else
        {
            this.arrowImage = this.arrowRight;
        }
    },
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (this.selectedLanguage == 'ar')
                {
                    this.arrowImage = this.arrowLeft;
                }
                else
                {
                    this.arrowImage = this.arrowRight;
                }
            }
        })
    },
    methods:
    {
        getArabicDate(dateTime)
        {
            return arabicDate.get(dateTime, this.selectedLanguage, true)
        },
        openPopup(object)
        {
            console.log(object)
            this.result = [];
            for (const key in object.dialogDetail)
            {
                if (Object.hasOwnProperty.call(object.dialogDetail, key))
                {
                    const element = object.dialogDetail[key];

                    if (element)
                    {
                        let dict = {
                            key: key,
                            value: element,
                            icon1: require(`@/assets/images/logos/Ellipse 258.png`),
                            icon2: require(`@/assets/images/logos/Vector (6).png`)
                        }
                        // if (typeof element != 'object')
                        // {
                        this.result.push(dict);
                        // }
                    }
                }
            }
            this.dialog = true;
        },
        closeDialog()
        {
            this.dialog = false;
        },
        onDetails(event, item)
        {
            event.stopPropagation();
            this.$emit('details', item)
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.v-application.theme--light .v-btn--is-elevated {
    /* box-shadow: 0 4px 8px -4px rgb(94 86 105 / 42%) !important; */
    box-shadow: none;
}

.v-application.theme--light .v-btn--is-elevated {
    box-shadow: none !important;
}

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.successColor {
    color: #09B4A9 !important
}

.text-alignment {
    text-align: right;
}

.en-close {
    position: absolute;
    right: 10px;
    top: 22px;
    cursor: pointer;
}

.card-text {
    height: 70vh;
    overflow-y: auto;
}

@media screen and (max-width: 600px) {

    .text-alignment {
        // text-align: left;
    }

    .arabic-alignment {
        text-align: left;
    }

}
</style>
