
const getArNumber = (counter) =>
{
    const english = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const farsi = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

    for (let i = 0; i < english.length; i++)
    {
        counter = counter.replaceAll(english[i], farsi[i]);
    }
    return counter;
}

export default {
    getArNumber
}