const statusCode = () =>
{
    return {
        VBLC: "S4",
        NEW: "S2",
        COMP: "C10",
        FC: "C12",
        PICK: "1G2", //todo not found, we can remove
        CLSC: "C16",
        CLSB: "C15",
        DMGC: "1G2", //todo not found, we can remove
        DSTR: "C14",
        CLSP: "S33",
        PCOM: "S5",
    }
}

const isLost = (code) =>
{
    return code == 'RLO' || code == 'C7';
}

const isStlc = (code) =>
{
    return code == 'C13' || code == 'RR5';
}

const isAtmc = (code) =>
{
    return code == 'C8' || code == 'RR1';
}

const isDmgd = (code) =>
{
    return code == 'C11' || code == 'RR4';
}

const skipCardCondition = (cardStatus) =>
{
    return cardStatus == statusCode.COMP ||
        cardStatus == statusCode.FC ||
        isLost(cardStatus) ||
        cardStatus == statusCode.PICK ||
        isStlc(cardStatus) ||
        cardStatus == statusCode.CLSC ||
        cardStatus == statusCode.CLSB ||
        isAtmc(cardStatus) ||
        cardStatus == statusCode.DMGC ||
        cardStatus == statusCode.DSTR ||
        cardStatus == statusCode.CLSP ||
        isDmgd(cardStatus) ||
        cardStatus == statusCode.PCOM;
}

export default {
    isLost,
    isAtmc,
    isDmgd,
    isStlc,
    skipCardCondition,
    statusCode,
    VBLC: "S4",
    NEW: "S2",
    COMP: "C10",
    FC: "C12",
    PICK: "1G2", //todo not found, we can remove
    CLSC: "C16",
    CLSB: "C15",
    DMGC: "1G2", //todo not found, we can remove
    DSTR: "C14",
    CLSP: "S33",
    PCOM: "S5",
}



