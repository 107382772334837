<template>
    <div class="auth-wrapper auth-v1"
         style="padding: 0;">
        <div v-if="modall">
            <BillingForm @close="closeDialog"
                         @success=successForm
                         :transaction="transaction"
                         :modal="modall"></BillingForm>
        </div>
        <div class="auth-inner main-container-width">
            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">

                    <v-card flat
                            class="full-min-height">
                        <app-header :details="cardDetail"></app-header>

                        <v-stepper v-model="step"
                                   class="custom">
                            <v-stepper-items>
                                <v-stepper-content step="1">
                                    <div class="mx-1 pt-4">
                                        <BackAction :text="text"
                                                    :output="output" />
                                    </div>
                                    <div class="mx-lg-8 mx-md-2 mx-sm-1 mx-1">
                                        <p class="text-lg-2xl text-xl font-weight-semibold text-black pt-5 mb-4"
                                           style="margin-bottom: 0;">
                                            {{ $t(`Choose transaction your are disputing`) }}
                                        </p>
                                        <v-card>
                                            <v-row class="d-flex justify-center mx-5"
                                                   style="padding-top: 36px;">

                                                <v-col cols="12"
                                                       lg="2"
                                                       md="12"
                                                       sm="12">
                                                    <div class="font-weight-regular text-blue"
                                                         style="font-size: 16px;padding-top: 9px;">
                                                        {{ $t(`Select Statement`) }}
                                                    </div>
                                                </v-col>

                                                <v-col lg="4"
                                                       md="12"
                                                       sm="12"
                                                       cols="12">
                                                    <v-select v-if="selectedLanguage == 'en'"
                                                              outlined
                                                              v-model="stmtDate"
                                                              :items="items"
                                                              item-text="dateEn"
                                                              item-value="dateValue"
                                                              class="selection"
                                                              @change="filterTransaction"
                                                              :placeholder="$t('Select Date')"
                                                              solo>
                                                    </v-select>
                                                    <v-select dir="rtl"
                                                              v-if="selectedLanguage == 'ar'"
                                                              outlined
                                                              v-model="stmtDate"
                                                              :items="items"
                                                              item-text="dateAr"
                                                              item-value="dateValue"
                                                              class="selection"
                                                              @change="filterTransaction"
                                                              :placeholder="$t('Select Date')"
                                                              solo>
                                                    </v-select>
                                                </v-col>

                                                <v-col lg="4"
                                                       md="12"
                                                       sm="12"
                                                       cols="12">
                                                    <v-text-field outlined
                                                                  v-model="searchKeyword"
                                                                  @keyup="filteredList()"
                                                                  solo
                                                                  class="search-icon"
                                                                  prepend-inner-icon="mdi-magnify"
                                                                  :placeholder="$t('Search Transactions')">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                        <div class="security py-4">
                                            <div v-if="loaded && transactionsArray.length == 0"
                                                 class="mt-6 text-center text-lg">
                                                {{ $t(`No transactions.`) }}
                                            </div>
                                            <v-container class=""
                                                         v-for="tran in transactionsArray"
                                                         :key="tran[0]">
                                                <div style="font-size: 13px;color: #596894;"
                                                     class="mt-4 font-weight-semibold">{{ tran[0] }}</div>
                                                <Transaction :transactionsArray="tran[1]"
                                                             :showBtn="showBtn"
                                                             @details="onDetails"></Transaction>
                                            </v-container>
                                        </div>

                                    </div>
                                </v-stepper-content>

                                <v-stepper-content step="2">

                                    <v-row class="pa-16 success-message">
                                        <v-col lg="12"
                                               class="text-center">
                                            <div class="circle">
                                                <img src="../../assets/images/Success.png"
                                                     alt=""
                                                     width="70px">
                                            </div>
                                            <p style="font-size: 14px;"
                                               class="text-black font-weight-semibold mt-5">
                                                {{ $t(`All done! Your billing dispute claim has been submitted. Our team
                                                will be in touch shortly.`) }}
                                            </p>
                                            <v-row>
                                                <v-col lg="12"
                                                       class="text-center">
                                                    <v-btn class=""
                                                           style="width: 100%;"
                                                           color="primary"
                                                           @click="goback"
                                                           type="button">
                                                        {{ $t('Done') }}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import { mdiMagnify } from '@mdi/js'
import AppHeader from '../test/AppHeader.vue'
// import soapServices from '@/services/soap-services'
import store from '@/store';
import BackAction from '@/@core/BackAction.vue';
import moment from 'moment'
import Transaction from '../transactions/Transaction.vue';
import getRecentActivity from '@/@core/utils/getRecentActivity';
import BillingForm from './BillingForm.vue';
export default {
    components: {
        AppHeader,
        BackAction,
        Transaction,
        BillingForm
    },

    data: () => ({
        step: 1,
        cardDetail: null,
        text: 'Back to Card Details',
        output: false,
        transactionsArray: [],
        transactionUnfiltered: [],
        transactionFiltered: [],
        menu2: false,
        menu1: false,
        showBtn: true,
        transaction: {},
        modall: false,
        loaded: false,
        stmtDate: '',
        icons: {
            mdiMagnify
        },
        searchKeyword: '',
        items: [],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }),

    mounted()
    {
        this.cardDetail = store.get('card/details');
        this.$route.params.cardType == 'primary' ? this.cardDetail = store.get('card/details') : this.cardDetail = store.get('card/supplementory')

        getRecentActivity.getStatementHistory().then(histpoyResp =>
        {
            this.items = histpoyResp.dateArray.reverse();
            this.getRecentActivity(true, histpoyResp.stmtDateTime);
        });
    },
    methods:
    {
        filteredList()
        {
            if (this.transactionUnfiltered.length > 0)
            {
                this.transactionFiltered = this.transactionUnfiltered.filter(tran =>
                {

                    if (tran.title)
                    {
                        return tran.title.toLowerCase().includes(this.searchKeyword.toLowerCase())
                    }
                });
                this.groupingTrans();
            }
        },
        goback()
        {
            window.history.back();
            window.scrollTo(0, 0);
        },
        getRecentActivity(grouping, dateTime)
        {
            getRecentActivity.getRecentActivity(grouping, dateTime, false).then(response =>
            {
                if (response)
                {
                    this.transactionsArray = response;
                    this.transactionUnfiltered = response;
                    this.transactionFiltered = response;

                    this.loaded = true;
                    this.groupingTrans()
                }
                this.loaded = true;
            });
        },
        filterTransaction()
        {
            this.getRecentActivity(true, this.stmtDate)
        },
        searchTransaction()
        {
            let newArr = []
            for (var j = 0; j < this.transactionUnfiltered.length; j++)
            {
                if (this.transactionUnfiltered[j].match(this.searchKeyword))
                {
                    newArr.push()
                }

            }
        },
        groupingTrans()
        {
            let list = this.transactionFiltered
            let dateTransactions = {};
            list.forEach(element =>
            {
                if (!(element.dateSort in dateTransactions))
                {
                    dateTransactions[element.dateSort] = [];
                }
                dateTransactions[element.dateSort].push(element);
            });

            let sortable = [];
            for (var key in dateTransactions)
            {
                sortable.push([key, dateTransactions[key]]);
            }

            sortable.sort(function (a, b)
            {
                return a[1] - b[1];
            });

            // sortable.reverse();
            this.transactionsArray = sortable
        },

        getDateGroup(item)
        {
            let arr = []
            let tranArr = []
            this.transactionsArray.forEach(element =>
            {
                if (item.date == element.date)
                {
                    arr.push({ date: element.date })
                    return
                }
            });
        },
        onDetails(item)
        {
            if (this.modall == false)
            {
                this.modall = true
            } else
            {
                this.modall = false
            }
            item.date = moment(item.date).format('DD-MMM-yy');
            item.postingDate = moment(item.postingDate).format('DD-MMM-yy');
            this.transaction = item
        },
        closeDialog()
        {
            this.modall = false;
        },
        successForm()
        {
            this.modall = false;
            this.step = 2;

        },
    },

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

::v-deep .search-icon .v-input__prepend-inner {

    margin-top: -7px;
}

::v-deep .selection .v-select__selection--comma {
    margin-top: -2px;
}

.circle {
    background: #E2F1FD;
    width: 118px;
    height: 115px;
    margin: 0 auto;
    padding: 31px 0px;
    border-radius: 70px;

    img {
        width: 55px;
    }
}

.success-message {
    width: 50%;
    margin: 0 auto;
}

@media screen and (max-width: 1000px) {
    .success-message {
        width: 100%;
        margin: 0 auto;
    }
}

::v-deep .v-input__control>.v-input__slot fieldset {
    color: rgba(94, 86, 105, 0.22);
    height: 51px;
}

input {
    color: rgba(94, 86, 105, 0.87);
    margin-bottom: 10px !important;
}

::v-deep .v-input input {
    max-height: 32px;
    padding-bottom: 17px;
}

.v-text-field.v-text-field--enclosed {
    margin: 0;
    padding: 0;
    height: 66px;
}

::v-deep .v-application.theme--light .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    /* box-shadow: 0 1px 3px 0 rgb(94 86 105 / 20%), 0 3px 1px -2px rgb(94 86 105 / 12%), 0 2px 2px 0 rgb(94 86 105 / 14%); */
    box-shadow: none !important;
}

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

::v-deep .v-input__append-inner .v-input__icon .v-icon {
    transform-origin: 10px;
    padding-bottom: 10px;
}

::v-deep .v-text-field--single-line .v-select__selections {
    padding-bottom: 1px !important;
}
</style>
